.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.responsive-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* 
   Each item gets a 1:1 aspect ratio 
   (change to aspect-ratio: 16/9 if you prefer).
*/
.gallery-item-custom {
  aspect-ratio: 3 / 2; 
  position: relative;
  overflow: hidden;
  cursor: pointer;
}


.gallery-item-custom img,
.gallery-item-custom video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover!important;
  object-position: center!important;
  display: block!important;
}

/* Remove any default figure/ul margins if needed */
figure, ul {
  margin: 0;
  padding: 0;
}

/* Tablet: 2 items per row */
@media (min-width: 640px) {
  .responsive-gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Desktop: 3 items per row */
@media (min-width: 1024px) {
  .responsive-gallery-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

